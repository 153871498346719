<template>
  <div>
    <b-modal
      title="Resultado de operación"
      v-model="show"
      no-close-on-backdrop
      hide-header-close
      size="md">
      
      <b-row v-for="(msg,i) in msgs" v-bind:key="i">
        <b-col lg="12">
          <CCard>
            <CCardBody>
              <b-row>
                <b-col lg="12" v-if="msg.date != null">
                  <strong>
                    {{msg.date|formatDateTime1}}
                  </strong>  
                </b-col>               
              </b-row>
              <b-row v-if="msg.hours != null">
                <b-col lg="12">
                  <CBadge shape="pill" color="success">Creado</CBadge>
                </b-col>
                <b-col 
                  lg="12"                   
                  v-for="(hour, i1) in msg.hours"
                  v-bind:key="i1">
                    {{hour.start.label}} - {{hour.end.label}}
                </b-col>
              </b-row>
              <b-row v-if="msg.description != ''">
                <b-col lg="12">
                  {{msg.description}}
                </b-col>
              </b-row>
              
              <!-- <b-row>
                <b-col lg="12">
                  <strong>Fecha:</strong> {{item.fecha | formatDateTime1}}
                </b-col>
                <b-col lg="8" offset-lg="">
                  <strong>Horas:</strong>
                  <div v-for="(hour, i) in item.horas" v-bind:key="i">
                    {{hour.start.label}} - {{hour.end.label}}
                  </div>
                </b-col>
              </b-row> -->
            </CCardBody>
          </CCard>
        </b-col>
      </b-row>
       <template #modal-footer>
        <div class="w-100">
          <b-row>           
            <b-col lg="12">
              <b-button 
                size="md" 
                variant="danger" 
                @click="hideModal"
                style="width: 100%; margin:">
                Cerrar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: "scheduleMsgModal",
    props: ['show', 'msgs'],
    data(){
      return {
        
      }
    },
    methods: {
      hideModal() {
        this.$store.commit("defShowScheduleMsg", false);
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>