const dates= [
  {
    day: "lun.",
    times: [],
    active: false
  },
  {
    day: "mar.",
    times: [],
    active: false
  },
  {
    day: "mié.",
    times: [],
    active: false
  },
  {
    day: "jue.",
    times: [],
    active: false
  },
  {
    day: "vie.",
    times: [],
    active: false
  },
  {
    day: "sáb.",
    times: [],
    active: false
  },
  {
    day: "dom.",
    times: [],
    active: false
  }
]

module.exports = dates