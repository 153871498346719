<template>
  <div>
    <b-modal
      title="Calendario fechas bloqueadas"
      v-model="show"
      no-close-on-backdrop
      hide-header-close
      size="md">
      
      <b-row v-for="item in schedule" v-bind:key="item.id">
        <b-col lg="12">
          <CCard>
            <CCardBody>
              <b-row>
                <b-col lg="12">
                  <strong>Fecha:</strong> {{item.fecha | formatDateTime1}}
                </b-col>
                <b-col lg="8" offset-lg="">
                  <strong>Horas:</strong>
                  <div v-for="(hour, i) in item.horas" v-bind:key="i">
                    {{hour.start.label}} - {{hour.end.label}}
                  </div>
                </b-col>
              </b-row>
            </CCardBody>
          </CCard>
        </b-col>
      </b-row>
       <template #modal-footer>
        <div class="w-100">
          <b-row>           
            <b-col lg="12">
              <b-button 
                size="md" 
                variant="danger" 
                @click="hideModal"
                style="width: 100%; margin:">
                Cerrar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: "scheduleModal",
    props: ['show', 'schedule'],
    data(){
      return {
        
      }
    },
    methods: {
      hideModal() {
        this.$store.commit("defShowProviderScheduleModal", false);
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>