<template>
  <div>
    <b-row>
      <b-col lg="6" offset-lg="3">
        <CCard>
          <CCardHeader>
            <h5>Define tu horario</h5>            
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col lg="12">
                <p><strong>1. Selecciona días individuales o rango de fechas.</strong></p>
              </b-col>
              <b-col lg="6">
                <b-form-group>
                  <!-- <small>Rango de fecha</small> -->
                  <v-date-picker
                    mode="range"
                    v-model="selectedDate"
                    color="blue"
                    is-inline
                    :min-date="minDate"
                    :max-date="maxDate"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-row>
                  <b-col lg="12">
                    <span><strong>Desde: </strong> {{selectedDate.start|formatDateTime1}}</span>
                  </b-col>
                  <b-col lg="12"> 
                    <span><strong>Hasta: </strong> {{selectedDate.end|formatDateTime1}}</span>
                  </b-col>
                  <b-col lg="12">
                    <b-button 
                      variant="info"
                      @click="getSchedule">
                        Ver horario bloqueados
                    </b-button>
                  </b-col>

                  <Modal 
                    :show="showScheduleModal"
                    :schedule="schedule"/>
                </b-row>
              </b-col>   

            </b-row>
            <br>
            <b-row>
              <b-col lg="12">
                <strong>
                  2. Indica las horas en que NO PRESTAS tu servicio
                </strong>
                <p></p>
              </b-col>
              
              <b-col lg="12">
                <ScheduleDays 
                  :schedule="{}" 
                  :dates="dates" 
                  :times="times"
                  @forbiddenDatesAndTimes="forbiddenDatesAndTimes=$event" />
              </b-col>
            </b-row>
          </CCardBody>
          <CCardFooter>
            <b-row>
              <b-col style="text-align:center">
                <b-button @click="saveSchedule" style="width:100%">
                  Guardar horario
                </b-button>
              </b-col>
              <b-col style="text-align:center">
                <b-button @click="deleteSchedule" style="width:100%" variant="danger">
                  Limpiar horario
                </b-button>
              </b-col>
            </b-row>
          </CCardFooter>
        </CCard>
      </b-col>
      <ModalMsg
       :msgs="msgs"
       :show="showScheduleMsgModal"/>
    </b-row>
      <!-- MinDate: {{minDate}}

      MaxDate: {{maxDate}} -->
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";
import moment from "moment";
moment.locale("es");

import times from "../../../utils/times";
import dates from "../../../utils/dates";
import ScheduleDays from "../../../components/ScheduleDays";
import Modal from "./Modal";
import ModalMsg from "./ModalManageSchedule"

const today = new Date();
export default {
  name: "providerSchedule",
  components: {
    "v-date-picker": DatePicker,
    ScheduleDays,
    Modal,
    ModalMsg,
  },
  data() {
    return {
      selectedDate: {
        start: new Date(today.getFullYear(), today.getMonth(), 1),
        end: new Date(today.getFullYear(), today.getMonth(), 15)
      },
      dates: dates,
      times: times,
      forbiddenDatesAndTimes: null,
      userProfile: null,
      schedule: null,
      showScheduleModal: false,
      showScheduleMsgModal: false,
      minDate: new Date(),
      maxDate: new Date(),
      msgs: []
    };
  },

  created(){
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
  
    this.watchs()
    this.setMaxDate()
  },
  computed: {
    timesFixed() {
      if (this.times) {
        let newTimes = [];
        this.times.map(time => {
          let id = time.value;
          if (
            id == 5 ||
            id == 9 ||
            id == 13 ||
            id == 17 ||
            id == 21 ||
            id == 25 ||
            id == 29 ||
            id == 33 ||
            id == 37 ||
            id == 41 ||
            id == 45 ||
            id == 49 
            
          ) {
            newTimes.push(time);
          }
        });
        return newTimes;
      } else {
        return [];
      }
    }
  },
  methods: {
    saveSchedule(){
      if(this.forbiddenDatesAndTimes == null){
        window.alert('Error: No tienes horarios para asignar')
      }
      let forbiddenDatesAndTimesFiltered = this.forbiddenDatesAndTimes
      .filter(
        forbiddenItem => forbiddenItem.active == true 
        && forbiddenItem.times.length > 0
      )
      if(forbiddenDatesAndTimesFiltered.length == 0){
        window.alert('Error: No hay horarios para almacenar')
      }
      let isValid = this.validate(forbiddenDatesAndTimesFiltered) 
      if(!isValid){
        window.alert('Error: No pueden haber campos de horarios sin gestionar')
      }else{
        let payload = {
          selectedDate: {
            start: moment(this.selectedDate.start).format('YYYY-MM-DD'),
            end: moment(this.selectedDate.end).format('YYYY-MM-DD')
          },
          forbiddenDatesAndTimes: forbiddenDatesAndTimesFiltered,
          userId: this.userProfile.usuario.id
        }

        let createWalkerSchedule = this.$firebase
          .functions()
          .httpsCallable('createWalkerSchedule')

        createWalkerSchedule(payload).then(res => {
          let data = res.data.data
          // console.log(data)        
          this.msgs = []
          data.map((item, i) => {
            let msgItem = null
            if('fecha' in item && 'horas' in item){
              msgItem = {
                date: moment(item.fecha).format('YYYY-MM-DD'),
                hours: item.horas,
                description: ''
              }
            
            } else if (item.status){
              let dateSend = moment(this.selectedDate.start)
                .add(i, 'days')
                .format('YYYY-MM-DD')
              msgItem = {
                date: dateSend,
                hours: null,
                description: item.description

              }            
            } else {
              msgItem = {
                date: null,
                hours: null,
                description: 'Error'
              }
            }
            this.msgs.push(msgItem)
          })   
          this.showScheduleMsgModal = true
          
        }).catch(error => {
          alert(error)        
        })
      }
      
    },
    validate(dt){
      let isValid = true

      let size = dt.length
      for(let i=0; i < size; i++){
        let times = dt[i].times
        let flag = 
        times.some(item => 
          (item.start == null || item.end == null) 
        )
        if(flag) {
          isValid = false;
          break;
        }
      }
      return isValid
    },
    saveSchedule2(){
      let forbiddenDatesAndTimesFiltered = this.forbiddenDatesAndTimes
      .filter(
        forbiddenItem => forbiddenItem.active == true 
        && forbiddenItem.times.length > 0
      )

      let payload = {
        selectedDate: {
          start: moment(this.selectedDate.start).format('YYYY-MM-DD'),
          end: moment(this.selectedDate.end).format('YYYY-MM-DD')
        },
        forbiddenDatesAndTimes: forbiddenDatesAndTimesFiltered,
        userId: this.userProfile.usuario.id
      }

      let createWalkerSchedule = this.$firebase
        .functions()
        .httpsCallable('createWalkerSchedule')

      createWalkerSchedule(payload).then(res => {
        let data = res.data.data
        console.log(data)        
        this.msgs = []
        data.map((item, i) => {
          let msgItem = null
          if('fecha' in item && 'horas' in item){
            msgItem = {
              date: moment(item.fecha).format('YYYY-MM-DD'),
              hours: item.horas,
              description: ''
            }
           
          } else if (item.status){
            let dateSend = moment(this.selectedDate.start)
              .add(i, 'days')
              .format('YYYY-MM-DD')
            msgItem = {
              date: dateSend,
              hours: null,
              description: item.description

            }            
          } else {
            msgItem = {
              date: null,
              hours: null,
              description: 'Error'
            }
          }
           this.msgs.push(msgItem)
        })   
        this.showScheduleMsgModal = true
        
      }).catch(error => {
        alert(error)        
      })

    },
    getSchedule(){
      let payload = {
        userId: this.userProfile.usuario.id,
        selectedDate: {
          start: moment(this.selectedDate.start)
            .format('YYYY-MM-DD') 
            + 'T00:00:00.000Z',
          end: moment(this.selectedDate.end)
            .format('YYYY-MM-DD')
            + 'T00:00:00.000Z'
        }
      }
      let getWalkerSchedule = this.$firebase
        .functions()
        .httpsCallable('getWalkerSchedule')

      getWalkerSchedule(payload).then(res => {
        let data = res.data.data
        data.map(item => {
          item.horas = JSON.parse(item.horas)
        })
        this.schedule = data
        this.showScheduleModal = true
      }).catch(error => {
        alert(error)
      })
    },
    deleteSchedule(){
      let payload = {
        userId: this.userProfile.usuario.id,
        selectedDate: {
          start: moment(this.selectedDate.start)
            .format('YYYY-MM-DD') 
            + 'T00:00:00.000Z',
          end: moment(this.selectedDate.end)
            .format('YYYY-MM-DD')
            + 'T00:00:00.000Z'
        }
      }

      let deleteSchedules = this.$firebase
        .functions()
        .httpsCallable('deleteWalkerSchedules')

      deleteSchedules(payload).then(response => {
        let data = response.data.data
        // console.log(data)
        window.alert('Limpieza exitosa. Los horarios han sido borrados')
      }).catch(error => {
        console.log(error)
        window.alert('No se pudo eliminar los horarios')
      })
    },
    watchs(){
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defShowProviderScheduleModal") {
          this.showScheduleModal = this.$store.getters.showProviderScheduleModal
        }
        if (mutation.type === "defShowScheduleMsg") {
          this.showScheduleMsgModal = this.$store.getters.showScheduleMsg
        }
      });
    },
    setMaxDate(){
      // Current month logic
      const currentYM = moment().
        format('YYYY-MM')
      const currentDay = moment(today)
        .format('DD')
      const firstDayOfMonth = moment()
        .startOf('month')
        .format('YYYY-MM-DD');
      const lastDayOfMonth   = moment()
        .endOf('month')
        .format('DD');
      const lastDateOfMonth   = moment()
        .endOf('month')
        .format('YYYY-MM-DD');

      // Next month logic
      const firstDayOfNextMonth = moment(firstDayOfMonth)
        .add(1, 'months')
        .format('YYYY-MM-DD hh:mm')
      const lastDayOfNextMonth = moment(firstDayOfNextMonth)
        .endOf('month')
        .format('DD');
      const nextYM = moment(firstDayOfNextMonth)
        .format('YYYY-MM')

      // if(currentDay >= 1 && currentDay <= 15){
      //   this.minDate = currentYM + '16'
      //   this.maxDate = lastDateOfMonth
      // }else if(currentDay >= 20 && currentDay <= lastDayOfMonth){
      //   this.minDate = firstDayOfNextMonth
      //   this.maxDate = nextYM + '-16'        
      // }

      this.minDate = currentYM + '-' + currentDay
      this.maxDate = nextYM + '-' + lastDayOfNextMonth
    },
  }
};
</script>

<style lang="scss" scoped></style>
