<template>
  <div>
    <!-- <b-row>
      <b-col lg="12">
        <b-alert v-model="flagErr" variant="danger" dismissible>
          {{errMsg}}
        </b-alert>
      </b-col>
    </b-row> -->
    
    <b-row v-for="(date, iDates) in dates" v-bind:key="iDates">

      <b-col cols="6">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="gridCheck"
            v-model="date.active"
          />
          <label class="form-check-label" for="gridCheck">
            {{ date.day }}
          </label>
        </div>
      </b-col>

      <b-col cols="6" style="text-align:right">
        <CButton
          color="success"
          size="sm"
          variant="ghost"
          @click="addTime(date)"
        >
          <CIcon name="cil-plus" />
        </CButton>
      </b-col>

      <b-col lg="12">
        <p></p>
        <p v-if="!date.active" style="color:#ccc">No disponible</p>
        <b-row v-for="(item, iTimes) in date.times" v-bind:key="iTimes">
          
          <b-col cols="5">
            <b-form-group>
              <v-select                
                :options="timesFixed"
                v-model="item.start"
                @input="startTimeChanged($event, date, iTimes)"
                :clearable="false"
              >
              </v-select>
            </b-form-group>
          </b-col>
          -
          <b-col cols="5">
            <b-form-group>
              <v-select
                :options="times"
                v-model="item.end"
                @input="endTimeChanged($event, date, iTimes)"
                disabled
                :clearable="false"
              >
              </v-select>
            </b-form-group>
          </b-col>
          
          <b-col style="text-align:right">
            
            <CButton
              color="danger"
              size="sm"
              variant="ghost"
              @click="deleteTime(date.times, iTimes, date)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="12">
        <hr />
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "schedule-days",
  props: ["schedule", "times", "dates"],
  data() {
    return {
      // flagErr: false,
      // errMsg: 'No pueden haber horas vacías'
    };
  },
  created() {
    this.schedule["dates"] = this.dates;
  },
  computed: {
     timesFixed() {
      if (this.times) {
        let newTimes = [];
        this.times.map(time => {
          let id = time.value;
          if (
            id == 5 ||
            id == 9 ||
            id == 13 ||
            id == 17 ||
            id == 21 ||
            id == 25 ||
            id == 29 ||
            id == 33 ||
            id == 37 ||
            id == 41 ||
            id == 45 ||
            id == 49 ||
            id == 53 ||
            id == 57
          ) {
            newTimes.push(time);
          }
        });
        return newTimes;
      } else {
        return [];
      }
    }
  },
  methods: {
    startTimeChanged(time, date, i) {

      let existTime = null
      existTime = date.times.filter(timeItem => 
        timeItem.start.value == time.value)

      if(existTime.length>1){
        date.times.splice(i,1)
      }else{
        let timeFound = null;        
        timeFound = this.times.find(t => t.value == time.value + 4);
        date.times[i].end = timeFound;
        this.schedule.dates = this.dates
      }
      this.$emit('forbiddenDatesAndTimes', this.schedule.dates) 
    },
    endTimeChanged(){

    },
    addTime(date) {
      
      date.active = true;
      let item = {
        start: null,
        end: null,
        // walker: null
      };    
      date.times.push(item);
    },
    deleteTime(times, i, date) {
      times.splice(i,1)
      if(times.length == 0)
        date.active = false
      this.schedule.dates = this.dates
      this.$emit('forbiddenDatesAndTimes', this.schedule.dates)
    },
    
  }
};
</script>

<style lang="scss" scoped></style>
